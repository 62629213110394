import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Todo from '../../templates/ContentfulToDo'

export default ({ pageContext: { todo = {} } }) => {
  // leftNav static query
  const data = useStaticQuery(graphql`
    {
      todoForNav: contentfulContentContainer(
        contentful_id: { eq: "4f8xhvMeYmlOnMW6nBz9GY" }
      ) {
        id
        contentful_id
        externalTitle
        content {
          ... on ContentfulToDoList {
            id
            externalTitle
            category
            image {
              description
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            listItems {
              ... on ContentfulToDo {
                id
                slug
                externalTitle
              }
            }
          }
        }
      }
    }
  `)

  return <Todo data={{ todo, allTodos: data.todoForNav }} />
}
