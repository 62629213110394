import React from 'react'
import { Router } from '@reach/router'

import PrivateRoute from '../components/app/privateRoute'

import Homepage from '../components/app/home'

import Profile from '../components/app/profile'
import UserProfile from '../components/app/userProfile'
import PetProfile from '../components/app/petProfile'

import Breed from '../components/app/breed'
import Article from '../components/app/article'
import Todo from '../components/app/todo'

import FourZeroFour from '../pages/404'

import { isLoggedIn, getUsername } from '../services/auth'
import hybridStorage from '../services/hybridStorage'
import { preCacheUserInfo } from '../services/zootopia'

// TODO: Add all the protected route components to the router - Note: They need to be components, not pages
//       (for which Gatsby creates routes automatically)
const App = (props) => {
  const pageSync = hybridStorage.handlePageSync()
  if (pageSync) {
    return pageSync
  }

  if (isLoggedIn()) {
    preCacheUserInfo()
    const userId = getUsername()

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'userDetails',
      userId: userId,
    })
  }

  return (
    <Router>
      <PrivateRoute exact path="/app/home" component={Homepage} />
      <PrivateRoute exact path="/app/profile" component={Profile} />
      <PrivateRoute exact path="/app/user-profile" component={UserProfile} />
      <PrivateRoute exact path="/app/pet-profile/:petid" component={PetProfile} />
      <PrivateRoute exact path="/app/pet-profile" component={PetProfile} />
      <PrivateRoute
        exact
        path="/todo"
        component={Todo}
        pageContext={props.pageContext}
      />

      <PrivateRoute
        path="/app/breed/:slug"
        component={Breed}
        pageContext={props.pageContext}
      />
      <PrivateRoute
        path="/todo/:slug"
        component={Todo}
        pageContext={props.pageContext}
      />
      <PrivateRoute
        path="/tips-and-guides/:category/:slug"
        component={Article}
        pageContext={props.pageContext}
      />

      <PrivateRoute path="/app/:star" component={FourZeroFour} />
    </Router>
  )
}

export default App
