import React from 'react'

import Layout from '../components/layout'

import { content, error, message } from './404.module.scss'

export default () => {
  return (
    <Layout layoutProps={{ className: '404' }}>
      <div className={content}>
        <div className={error}>
          <img src="/img/pug.png" alt="Illustration of a sad looking dog." />
          <div className={message}>
            Thanks for piloting Floof with us! On April 13th we sunset Floof in
            preparation for our next adventure. We can't wait to share it with
            you. Until then!
          </div>
        </div>
      </div>
    </Layout>
  )
}
