import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import moment from 'moment'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import SEO from '../seo'
import Layout from '../layout'
import BreedLeftNav from '../leftNav'

import { convertCmToInches, convertKgToPounds } from '../../utils/pet'
import { getPetsWithBreedInfo } from '../../services/zootopia'

import FourZeroFour from '../../pages/404'

import './breed.scss'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`breed-tabpanel-${index}`}
      aria-labelledby={`breed-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const a11yProps = (index) => ({
  id: `breed-tab-${index}`,
  'aria-controls': `breed-tabpanel-${index}`,
})

const a11yBreedProps = (index) => ({
  id: `breed-${index}`,
  'aria-controls': `breed-tab-${index}`,
})

const BreedTabs = withStyles({
  root: {
    margin: '0 -1rem 0.6875rem',
  },
  indicator: {
    display: 'none',
  },
})((props) => <Tabs {...props} />)

const BreedTab = withStyles({
  root: {
    color: '#202545',
    backgroundColor: '#ffffff',
    border: '1px solid #ebecef',
    fontFamily: ['GTAmerica-Regular', 'sans-serif'].join(','),
    fontSize: '1rem',
    lineHeight: '1.25rem',
    marginLeft: '1rem',
    textTransform: 'none',
    width: 160,
    '&:hover': {
      backgroundColor: '#ebecef',
      color: '#1f2447',
    },
  },
  selected: {
    backgroundColor: '#ebecef',
    border: '1px solid #ebecef',
    color: '#1f2447',
    fontFamily: ['GTAmerica-Bold', 'sans-serif'].join(','),
    fontSize: '1.0625rem',
  },
})((props) => <Tab disableRipple {...props} />)

const RenderApiDetails = ({ children }) => (
  <div>
    <ReactMarkdown disallowedTypes={['link']} unwrapDisallowed={true}>
      {children}
    </ReactMarkdown>
  </div>
)

export default ({ pageContext: { slug, breed } }) => {
  if (!breed) {
    return <FourZeroFour />
  }

  const breedAttributes = breed.raw.attributes
  const breedRelationships = breed.raw.relationships

  const [isLoading, setIsLoading] = useState(true)

  const [petName, setPetName] = useState('')
  const [petBreedInfo, setPetBreedInfo] = useState([])
  const [petBirthDate, setPetBirthDate] = useState(0)

  const [breedversityValue, setBreedversityValue] = useState(0)
  const [breedCareValue, setBreedCareValue] = useState(0)
  const [breedLayoutProps, setBreedLayoutProps] = useState({})
  const [currentBreed, setCurrentBreed] = useState(0)

  const [ownerHasBreed, setOwnerHasBreed] = useState(false)

  const handleBreedVersityChange = (event, index) => {
    setBreedversityValue(index)
  }

  const handleBreedCareChange = (event, index) => {
    setBreedCareValue(index)
  }

  const getRecommmendedPetActivity = () => {
    // Puppy: 2 years (730 days) or younger
    // Adult: Over 2 years - 7 years (731 - 2555 days)
    // Senior: Over 7 years (2556 days or more)
    let recommendedTime

    const today = moment()
    const ageInDays = today.diff(moment(petBirthDate), 'days')

    if (ageInDays <= 730)
      recommendedTime = breedAttributes['recommended-activity-mins-young']
    else if (ageInDays >= 731 && ageInDays <= 2555)
      recommendedTime = breedAttributes['recommended-activity-mins-adult']
    else recommendedTime = breedAttributes['recommended-activity-mins-senior']

    return `${recommendedTime} minutes `
  }

  useEffect(() => {
    let didCancel = false

    const getBreedDetails = async () => {
      setIsLoading(true)

      const allPets = await getPetsWithBreedInfo()
      if (!didCancel) {
        setPetName(allPets[0].name)

        let petNames = []
        allPets.forEach((pet) => {
          ;[1, 2, 3, 4].forEach((i) => {
            if (pet[`wisdom_breed_id_${i}`] === breed.wisdomId) {
              petNames.push(pet.name)
              setOwnerHasBreed(true)

              // TODO: Got to rethink this when multi-pet
              setPetBreedInfo(pet.breed_info)
              setCurrentBreed(i - 1)
              setPetBirthDate(pet.birth_date)
              if (pet.breed_info.length === 1)
                setBreedLayoutProps({ 'data-fullwidth': true })
            }
          })
        })
        setPetName(petNames.length > 1 ? petNames.join(', ') : petNames[0])

        setIsLoading(false)
      }
    }

    getBreedDetails()

    return () => (didCancel = true)
  }, [])

  // For Google Analytics
  const trackTabEvent = (tab) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'aboutDog',
      clickText: tab,
    })
  }

  return (
    <Layout>
      <SEO
        bodyAttributes={{ class: 'breed-page' }}
        title={`${breed.name}-versity`}
        description={`Everything you wanted to know about ${breed.plural}, but were afraid too ask`}
      />

      {!isLoading ? (
        <div {...breedLayoutProps}>
          {petBreedInfo.length && petBreedInfo.length > 1 ? (
            <BreedLeftNav>
              <header>{petName}'s Breeds</header>
              <ul>
                {petBreedInfo.map((breedInfo) => (
                  <li
                    key={breedInfo.wisdomId}
                    className={breedInfo.slug === slug ? 'active' : ''}
                  >
                    <Link to={`/app/breed/${breedInfo.slug}/`}>
                      {breedInfo.name}
                    </Link>
                  </li>
                ))}
                <li>
                  <p>Want to learn more about {petName}'s breed(s)?</p>
                  <a
                    href="https://www.wisdompanel.com/products"
                    target="_blank"
                  >
                    Take a Wisdom Panel&trade; dog
                    <br />
                    DNA test.
                    <i>
                      <ArrowForwardIcon />
                    </i>
                  </a>
                </li>
              </ul>
              <BreedTabs
                value={currentBreed}
                aria-label={`${breed.name} details`}
                variant="scrollable"
                scrollButtons="auto"
              >
                {petBreedInfo.map((breedInfo, idx) => (
                  <BreedTab
                    label={breedInfo.name}
                    href={`/app/breed/${breedInfo.slug}/`}
                    key={breedInfo.wisdomId}
                    {...a11yBreedProps(idx)}
                  />
                ))}
              </BreedTabs>
            </BreedLeftNav>
          ) : null}

          <div className="BreedDetails">
            <header className="BreedHeader">
              <div className="BreedImage">
                <img src={breed.colorImage} alt={breed.plural} loading="lazy" />
              </div>

              <div className="BreedQuickDetails">
                <h2>{breed.name}</h2>

                <div className="BreedAverages">
                  <div>
                    <h3>Weight</h3>
                    <p>{`${convertKgToPounds(
                      breedAttributes['weight-range-pet-min']
                    )}-${convertKgToPounds(
                      breedAttributes['weight-range-pet-max']
                    )} lb`}</p>
                  </div>

                  <div>
                    <h3>Height</h3>
                    <p>{`${convertCmToInches(
                      breedAttributes['height-range-min']
                    )}-${convertCmToInches(
                      breedAttributes['height-range-max']
                    )} in`}</p>
                  </div>

                  <div>
                    <h3>Breed Group</h3>
                    <p>
                      {
                        breedRelationships['breed-group'].fetched.attributes
                          .name
                      }
                    </p>
                  </div>
                </div>
              </div>
            </header>
            <section className="BreedAbout">
              <h3>About the breed</h3>
              <RenderApiDetails>{breedAttributes.facts}</RenderApiDetails>
            </section>

            <section className="Breedversity">
              <header>
                <h2>{breed.name}-versity</h2>
                <p>
                  Everything you wanted to know about {petName} and{' '}
                  {breed.plural}, but were afraid too ask
                </p>
              </header>

              <BreedTabs
                value={breedversityValue}
                onChange={handleBreedVersityChange}
                aria-label={`${breed.name}-versity details`}
                variant="scrollable"
                scrollButtons="auto"
              >
                <BreedTab
                  label="Traits"
                  {...a11yProps(0)}
                  onClick={() => {
                    trackTabEvent('Traits')
                  }}
                />
                <BreedTab
                  label="Temperament"
                  {...a11yProps(1)}
                  onClick={() => {
                    trackTabEvent('Temperament')
                  }}
                />
                <BreedTab
                  label="Body"
                  {...a11yProps(2)}
                  onClick={() => {
                    trackTabEvent('Body')
                  }}
                />
                <BreedTab
                  label="History"
                  {...a11yProps(3)}
                  onClick={() => {
                    trackTabEvent('History')
                  }}
                />
              </BreedTabs>
              {/* Traits */}
              <TabPanel value={breedversityValue} index={0}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_traits.png"
                      alt="Traits Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <div>
                      <h3>General Appearance</h3>
                      <RenderApiDetails>
                        {breedAttributes['traits-appearance']}
                      </RenderApiDetails>
                    </div>

                    <div>
                      <h3>Distinctive Physical Traits</h3>
                      <RenderApiDetails>
                        {breedAttributes['traits-physical']}
                      </RenderApiDetails>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* Temperament */}
              <TabPanel value={breedversityValue} index={1}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_temperment.png"
                      alt="Temperment Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <RenderApiDetails>
                      {breedAttributes.temperament}
                    </RenderApiDetails>
                  </div>
                </div>
              </TabPanel>

              {/* Body */}
              <TabPanel value={breedversityValue} index={2}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_body.png"
                      alt="Body Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <h3>Coat and Coloring</h3>
                    <RenderApiDetails>
                      {breedAttributes['traits-coat-and-coloring']}
                    </RenderApiDetails>
                  </div>
                </div>
              </TabPanel>

              {/* History */}
              <TabPanel value={breedversityValue} index={3}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_history.png"
                      alt="History Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <RenderApiDetails>
                      {breedAttributes.history}
                    </RenderApiDetails>
                  </div>
                </div>
              </TabPanel>
            </section>

            <section className="BreedExerciseAndActivity">
              <div>
                <h2>Exercise and Activity</h2>
                <RenderApiDetails>
                  {breedAttributes['care-exercise']}
                </RenderApiDetails>
              </div>

              <div className="BreedActivityPlanContainer">
                <div className="BreedActivityPlan">
                  {ownerHasBreed ? (
                    <>
                      <div className="BreedActivityTracker">
                        <div className="circle">
                          <span>
                            <img
                              src={breed.colorImage}
                              alt={breed.plural}
                              width={73}
                              height={58}
                            />
                          </span>
                        </div>
                      </div>
                      <div>
                        <h3>{petName}'s activity plan</h3>
                        <p>
                          Try to average {getRecommmendedPetActivity(breed)} of
                          exercise a day
                        </p>
                      </div>
                    </>
                  ) : null}
                </div>
                <a
                  href="https://shop.whistle.com/products/whistle-go-explore-gps-pet-tracker-activity-monitor?utm_medium=partner&utm_source=floof&utm_campaign=20-off-explore"
                  target="_blank"
                >
                  Track your dog's activity level with a WHISTLE&trade; Fit
                  <i>
                    <ArrowForwardIcon />
                  </i>
                </a>
              </div>
            </section>

            <section className="BreedCare">
              <div>
                <h2>
                  How to care for{' '}
                  {ownerHasBreed ? `dogs like ${petName}` : breed.plural}
                </h2>
                <p>{breed.floofTagline}</p>
              </div>

              <BreedTabs
                value={breedCareValue}
                onChange={handleBreedCareChange}
                aria-label={`${breed.name}-care details`}
                variant="scrollable"
                scrollButtons="auto"
              >
                <BreedTab
                  label="Grooming"
                  {...a11yProps(0)}
                  onClick={() => {
                    trackTabEvent('Grooming')
                  }}
                />
                <BreedTab
                  label="Nutrition"
                  {...a11yProps(1)}
                  onClick={() => {
                    trackTabEvent('Nutrition')
                  }}
                />
                <BreedTab
                  label="Training"
                  {...a11yProps(2)}
                  onClick={() => {
                    trackTabEvent('Training')
                  }}
                />
              </BreedTabs>
              {/* Grooming */}
              <TabPanel value={breedCareValue} index={0}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_grooming.png"
                      alt="Grooming Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <RenderApiDetails>
                      {breedAttributes['care-grooming']}
                    </RenderApiDetails>
                  </div>
                </div>
              </TabPanel>

              {/* Nutrition */}
              <TabPanel value={breedCareValue} index={1}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_nutrition.png"
                      alt="Nutrition Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <RenderApiDetails>
                      {breedAttributes['care-nutrition']}
                    </RenderApiDetails>
                  </div>
                </div>
              </TabPanel>

              {/* Training */}
              <TabPanel value={breedCareValue} index={2}>
                <div className="BreedTabWithIllustration">
                  <div>
                    <img
                      src="/img/breed/breedpage_training.png"
                      alt="Nutrition Illustration"
                      height="280"
                      width="280"
                    />
                  </div>
                  <div className="TabPanelContent">
                    <RenderApiDetails>
                      {breedAttributes['care-training']}
                    </RenderApiDetails>
                  </div>
                </div>
              </TabPanel>
            </section>
          </div>
        </div>
      ) : null}
    </Layout>
  )
}
