import React from 'react'

import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import SEO from '../components/seo'

import Layout from '../components/layout'
import LeftnavArticle from '../components/leftNavArticle'
import VetInsights from '../components/articleVetInsights'
import EmbeddedEntry from '../components/articleEmbeddedEntry'

import './ContentfulArticle.scss'

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node.data.target.fields.internalTitle['en-US'] == 'VETINSIGHT_CHAT') {
        return <VetInsights />
      } else {
        return <EmbeddedEntry />
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const url = node.data.target.fields.file['en-US'].url
      const alt = node.data.target.fields.file['en-US'].description
      return <img src={url} alt={alt} />
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.uri} target="_blank" rel="nofollow">
          {children}
        </a>
      )
    },
  },
}

const ArticleTemplate = ({ data: { article } }) => {
  return (
    <Layout>
      <SEO
        bodyAttributes={{ class: 'article' }}
        title={article.externalTitle}
        description={article.seoDescription}
        canonical={article.canonicalUrl}
      />

      <div>
        <LeftnavArticle articleSlug={article.slug} />

        <div className="articleBody" id="articleBody">
          <h1>{article.externalTitle}</h1>
          {article.author && (
            <div className="articleByline">
              By{' '}
              {article.author.map((author) => {
                return <span key={author.id}>{author.name}</span>
              })}
            </div>
          )}

          {article.source ? (
            <div className="articleSource">
              Originally published by {article.source}
            </div>
          ) : null}

          {article.mainImage && (
            <div className="articleMainImage">
              <Img
                fluid={article.mainImage.fluid}
                alt={article.mainImage.description}
              />
            </div>
          )}

          <div className="articleBodyContent">
            {documentToReactComponents(article.body.json, options)}
          </div>

          <section className="relatedPosts">
            {article.relatedArticles && (
              <>
                <h2 className="relatedHeading">More like this</h2>
                <div className="relatedArticles">
                  {article.relatedArticles.map((item) => {
                    const categorySlug = ('' + item.category)
                      .toLowerCase()
                      .trim()
                      .replace(/[^a-z0-9]+/g, '-')
                    const path = `/tips-and-guides/${categorySlug}/${item.slug}`
                    return (
                      <div className="relatedArticle" key={item.id}>
                        <Link to={path}>
                          <Img
                            fluid={item.mainImage.fluid}
                            alt={item.mainImage.description}
                          />
                        </Link>
                        <Link className="relatedArticleTitle" to={path}>
                          {item.externalTitle}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </Layout>
  )
}
export default ArticleTemplate
