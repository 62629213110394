import React from 'react'
import Img from 'gatsby-image'

export default ({ className, image, imageProps = {}, isFixed }) => {
  const { file = {}, description = '', svg, url } = image

  if (file.contentType === 'image/svg+xml') {
    if (svg && svg.content) {
      // Inlined SVGs
      return <div dangerouslySetInnerHTML={{ __html: svg.content }} />
    }

    // SVGs that can/should not be inlined
    return <img src={url} alt={description} loading="lazy" />
  }

  const imageType = !isFixed ? 'fluid' : 'fixed'
  const img = { [imageType]: image[imageType] }
  // Non SVG images
  return <Img {...img} {...imageProps} alt={description} />
}
