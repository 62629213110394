import React, { useState, useEffect } from 'react'

import Layout from '../layout'

import { useFormik } from 'formik'
import { object, string, date, email } from 'yup'
import { useAlert, Alert } from '../../hooks/alert'
import moment from 'moment'
import 'moment/min/locales'
import { Link } from 'gatsby'
import Snackbar from '@material-ui/core/Snackbar'

import {
  getBreedAll,
  getPets,
  appendPetsWithBreedInfo,
  appendPetsWithAdditionalInfo,
  createPet,
  updatePet,
  updatePetAdditionalInfo,
} from '../../services/zootopia'

import { dummy } from './profile.module.scss'

const PetProfile = (props = {}) => {
  const [alertOpen, alertMessage, openAlert, closeAlert] = useAlert()
  const handleAlertClose = (event, reason) => closeAlert(reason)

  const [pet, setPet] = useState({})
  const [allPets, setAllPets] = useState([])
  const [allBreeds, setAllBreeds] = useState([])
  const [petId, setPetId] = useState(props.petid || null)
  const [editing, setEditing] = useState({
    basic: false,
    care: false,
    health: false,
    vet: false,
    emergencyContact: false,
  })

  if (navigator && navigator.languages && navigator.languages.length) {
    moment.locale(navigator.languages[0])
  }

  const nullDate = '1970-01-01T00:00:00.000Z'

  const formik = {
    basic: useFormik({
      enableReinitialize: true,
      initialValues: {
        name: pet.name,
        gender: pet.gender,
        microchipid: pet.microchipid,
        spayed_or_neutered: pet.spayed_or_neutered,
        birthDate: moment.utc(pet.birth_date || nullDate).format('L'),
        acquisitionDate: moment
          .utc(pet.acquisition_date || nullDate)
          .format('L'),
        lastVetVisit: pet.last_vet_visit
          ? moment.utc(pet.last_vet_visit).format('L')
          : '',
        size: pet.size,
        isFirstPet: pet.is_first_pet,
        breed: pet.breed,
        wisdom_breed_id_1: pet.wisdom_breed_id_1,
        wisdom_breed_id_2: pet.wisdom_breed_id_2,
        wisdom_breed_id_3: pet.wisdom_breed_id_3,
        wisdom_breed_id_4: pet.wisdom_breed_id_4,
        lastModified: moment(pet.modified_at || nullDate).format('L'),
      },
      validationSchema: object({
        name: string().required('Name is required'),
        gender: string().required('Gender is required'),
        birthDate: date()
          .required('birthDate is required')
          .required(`${name}'s birthday is required`)
          .max(new Date(), `Birthday cannot be in the future`),
        acquisitionDate: date()
          .required('acquisitionDate is required')
          .required(`${name}'s birthday is required`)
          .max(new Date(), `acq cannot be in the future`),
        size: string().required('size is required'),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          const breedIds = [
            values.wisdom_breed_id_1,
            values.wisdom_breed_id_2,
            values.wisdom_breed_id_3,
            values.wisdom_breed_id_4,
          ].filter((id) => id !== '' && id)

          const uniqueBreedIds = Array.from(new Set(breedIds))

          const breedNames = uniqueBreedIds.map((selectedId) => {
            const breed = allBreeds.filter((breed) => breed.id == selectedId)
            return breed[0].name
          })

          let payload = {
            animal_type: 'Dog',
            name: values.name,
            birth_date: moment.utc(values.birthDate).format('YYYY-MM-DD'),
            acquisition_date: moment
              .utc(values.acquisitionDate)
              .format('YYYY-MM-DD'),
            size: values.size,
            is_first_pet: values.isFirstPet ? 'true' : 'false',
            gender: values.gender,
            microchipid: values.microchipid || '',
            spayed_or_neutered: values.spayed_or_neutered || '',
            breed: breedNames.join(' / ') || '',
            wisdom_breed_id_1: uniqueBreedIds[0] || '',
            wisdom_breed_id_2: uniqueBreedIds[1] || '',
            wisdom_breed_id_3: uniqueBreedIds[2] || '',
            wisdom_breed_id_4: uniqueBreedIds[3] || '',
          }

          if ( values.lastVetVisit ) {
            payload.last_vet_visit = moment.utc(values.lastVetVisit).format('YYYY-MM-DD')
	        }

          if (petId === 'add') {
            const rt = await createPet(payload)
            setPetId(rt.id)
          } else {
            await updatePet(petId, payload)
          }

          await loadData()
          resetForm({})
        } catch (err) {
          console.log(err)
          openAlert(err.message)
        }
      },
    }),
    care: useFormik({
      enableReinitialize: true,
      initialValues: {
        feeding: pet?.additional_info?.care?.data?.feeding || '',
        walking: pet?.additional_info?.care?.data?.walking || '',
        sleeping: pet?.additional_info?.care?.data?.sleeping || '',
        lastModified: moment(
          pet?.additional_info?.care?.modified_at || nullDate
        ).format('L'),
      },
      validationSchema: object({
        feeding: string(),
        walking: string(),
        sleeping: string(),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          const payload = {
            feeding: values.feeding,
            walking: values.walking,
            sleeping: values.sleeping,
          }

          await updatePetAdditionalInfo(petId, 'care', payload)
          await loadData()
          resetForm({})
        } catch (err) {
          console.log(err)
          openAlert(err.message)
        }
      },
    }),
    health: useFormik({
      enableReinitialize: true,
      initialValues: {
        allergies: pet?.additional_info?.health?.data?.allergies || '',
        medications: pet?.additional_info?.health?.data?.medications || '',
        lastModified: moment(
          pet?.additional_info?.health?.modified_at || nullDate
        ).format('L'),
      },
      validationSchema: object({
        allergies: string(),
        medications: string(),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          const payload = {
            allergies: values.allergies,
            medications: values.medications,
          }

          await updatePetAdditionalInfo(petId, 'health', payload)
          await loadData()
          resetForm({})
        } catch (err) {
          console.log(err)
          openAlert(err.message)
        }
      },
    }),
    vet: useFormik({
      enableReinitialize: true,
      initialValues: {
        name: pet?.additional_info?.vet?.data?.name || '',
        address: pet?.additional_info?.vet?.data?.address || '',
        telephone: pet?.additional_info?.vet?.data?.telephone || '',
        email: pet?.additional_info?.vet?.data?.email || '',
        lastModified: moment(
          pet?.additional_info?.vet?.modified_at || nullDate
        ).format('L'),
      },
      validationSchema: object({
        name: string(),
        address: string(),
        telephone: string(),
        email: string().email(),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          const payload = {
            name: values.name,
            address: values.address,
            email: values.email,
            telephone: values.telephone,
          }

          await updatePetAdditionalInfo(petId, 'vet', payload)
          await loadData()
          resetForm({})
        } catch (err) {
          console.log(err)
          openAlert(err.message)
        }
      },
    }),
    emergencyContact: useFormik({
      enableReinitialize: true,
      initialValues: {
        name: pet?.additional_info?.emergencyContact?.data?.name || '',
        telephone:
          pet?.additional_info?.emergencyContact?.data?.telephone || '',
        email: pet?.additional_info?.emergencyContact?.data?.email || '',
        lastModified: moment(
          pet?.additional_info?.emergencyContact?.modified_at || nullDate
        ).format('L'),
      },
      validationSchema: object({
        name: string(),
        telephone: string(),
        email: string().email(),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          const payload = {
            name: values.name,
            email: values.email,
            telephone: values.telephone,
          }

          await updatePetAdditionalInfo(petId, 'emergencyContact', payload)
          await loadData()
          resetForm({})
        } catch (err) {
          console.log(err)
          openAlert(err.message)
        }
      },
    }),
  }

  const loadData = async () => {
    if (petId === 'add') {
      setEditing({ ...editing, basic: true })
      return
    }

    const petsData = await getPets()
    const petsDataWithAdditionalInfo = await appendPetsWithAdditionalInfo(
      petsData
    )
    setAllPets(petsDataWithAdditionalInfo)

    const foundPet = petsDataWithAdditionalInfo.filter((p) => p.id == petId)

    // couldn't find i pet with matching slug id
    if (foundPet.length === 1) {
      setPet(foundPet[0])
    } else {
      setPet(petsDataWithAdditionalInfo[0])
      setPetId(petsDataWithAdditionalInfo[0].id)

      // should i redirect instead?
    }
  }

  const loadAllBreeds = async () => {
    const defaultBreed = [{ id: '', name: '' }]
    const all = (await getBreedAll())
      .map((b) => {
        return { id: b._source.wisdomId, name: b._source.name }
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
        return 0
      })

    setAllBreeds([...defaultBreed, ...all])
  }

  useEffect(() => {
    loadAllBreeds()
    loadData()
  }, [])

  return (
    <Layout>
      <h1>Pet Profiles</h1>
      <section
        className={dummy}
        style={{ display: editing.basic ? 'block' : 'none' }}
      >
        <form id="pet-profile-form" onSubmit={formik.basic.handleSubmit}>
          <div>
            <fieldset className={dummy}>
              <div>
                <label className={dummy} htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className={dummy}
                  name="name"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.name && formik.basic.touched.name
                  }
                  value={formik.basic.values.name}
                  placeholder="Name"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.name && formik.basic.errors.name ? (
                  <div className={dummy}>{formik.basic.errors.name}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="birthDate">
                  birthDate
                </label>
                <input
                  type="text"
                  id="birthDate"
                  className={dummy}
                  name="birthDate"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.birthDate &&
                    formik.basic.touched.birthDate
                  }
                  value={formik.basic.values.birthDate}
                  placeholder="birthDate"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.birthDate &&
                formik.basic.errors.birthDate ? (
                  <div className={dummy}>{formik.basic.errors.birthDate}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="acquisitionDate">
                  acquisitionDate
                </label>
                <input
                  type="text"
                  id="acquisitionDate"
                  className={dummy}
                  name="acquisitionDate"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.acquisitionDate &&
                    formik.basic.touched.acquisitionDate
                  }
                  value={formik.basic.values.acquisitionDate}
                  placeholder="acquisitionDate"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.acquisitionDate &&
                formik.basic.errors.acquisitionDate ? (
                  <div className={dummy}>
                    {formik.basic.errors.acquisitionDate}
                  </div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="lastVetVisit">
                  lastVetVisit
                </label>
                <input
                  type="text"
                  id="lastVetVisit"
                  className={dummy}
                  name="lastVetVisit"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.lastVetVisit &&
                    formik.basic.touched.lastVetVisit
                  }
                  value={formik.basic.values.lastVetVisit}
                  placeholder="lastVetVisit"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.lastVetVisit &&
                formik.basic.errors.lastVetVisit ? (
                  <div className={dummy}>
                    {formik.basic.errors.lastVetVisit}
                  </div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="microchipid">
                  microchipid
                </label>
                <input
                  type="text"
                  id="microchipid"
                  className={dummy}
                  name="microchipid"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.microchipid &&
                    formik.basic.touched.microchipid
                  }
                  value={formik.basic.values.microchipid}
                  placeholder="microchipid"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.microchipid &&
                formik.basic.errors.microchipid ? (
                  <div className={dummy}>{formik.basic.errors.microchipid}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="size">
                  gender
                </label>
                <select
                  id="gender"
                  className={dummy}
                  name="gender"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.gender && formik.basic.touched.gender
                  }
                  value={formik.basic.values.gender}
                  placeholder="gender"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  <option value="F">Female</option>
                  <option value="M">Male</option>
                </select>
                {formik.basic.touched.gender && formik.basic.errors.gender ? (
                  <div className={dummy}>{formik.basic.errors.gender}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="size">
                  spayed_or_neutered
                </label>
                <select
                  id="spayed_or_neutered"
                  className={dummy}
                  name="spayed_or_neutered"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.spayed_or_neutered &&
                    formik.basic.touched.spayed_or_neutered
                  }
                  value={formik.basic.values.spayed_or_neutered}
                  placeholder="spayed_or_neutered"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  <option value=""></option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                {formik.basic.touched.spayed_or_neutered &&
                formik.basic.errors.spayed_or_neutered ? (
                  <div className={dummy}>
                    {formik.basic.errors.spayed_or_neutered}
                  </div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="size">
                  size
                </label>
                <select
                  id="size"
                  className={dummy}
                  name="size"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.size && formik.basic.touched.size
                  }
                  value={formik.basic.values.size}
                  placeholder="size"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  <option>Toy</option>
                  <option>Small</option>
                  <option>Medium</option>
                  <option>Large</option>
                  <option>Giant</option>
                </select>
                {formik.basic.touched.size && formik.basic.errors.size ? (
                  <div className={dummy}>{formik.basic.errors.size}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="isFirstPet">
                  isFirstPet
                </label>
                <input
                  id="c1"
                  type="checkbox"
                  name="isFirstPet"
                  checked={formik.basic.values.isFirstPet}
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                <label htmlFor="c1" className={dummy}>
                  isFirstPet
                </label>
                {formik.basic.touched.isFirstPet &&
                formik.basic.errors.isFirstPet ? (
                  <div className={dummy}>{formik.basic.errors.isFirstPet}</div>
                ) : null}
              </div>

              <div>
                <label className={dummy} htmlFor="wisdom_breed_id_1">
                  breed
                </label>
                <select
                  id="wisdom_breed_id_1"
                  className={dummy}
                  name="wisdom_breed_id_1"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.gender && formik.basic.touched.gender
                  }
                  value={formik.basic.values.wisdom_breed_id_1}
                  placeholder="gender"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  {allBreeds.map((breed) => (
                    <option value={breed.id}>{breed.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className={dummy} htmlFor="wisdom_breed_id_2">
                  breed
                </label>
                <select
                  id="wisdom_breed_id_2"
                  className={dummy}
                  name="wisdom_breed_id_2"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.gender && formik.basic.touched.gender
                  }
                  value={formik.basic.values.wisdom_breed_id_2}
                  placeholder="gender"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  {allBreeds.map((breed) => (
                    <option value={breed.id}>{breed.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className={dummy} htmlFor="wisdom_breed_id_3">
                  breed
                </label>
                <select
                  id="wisdom_breed_id_3"
                  className={dummy}
                  name="wisdom_breed_id_3"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.gender && formik.basic.touched.gender
                  }
                  value={formik.basic.values.wisdom_breed_id_3}
                  placeholder="gender"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  {allBreeds.map((breed) => (
                    <option value={breed.id}>{breed.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className={dummy} htmlFor="wisdom_breed_id_4">
                  breed
                </label>
                <select
                  id="wisdom_breed_id_4"
                  className={dummy}
                  name="wisdom_breed_id_4"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.gender && formik.basic.touched.gender
                  }
                  value={formik.basic.values.wisdom_breed_id_4}
                  placeholder="gender"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                >
                  {allBreeds.map((breed) => (
                    <option value={breed.id}>{breed.name}</option>
                  ))}
                </select>
              </div>
            </fieldset>
          </div>

          <div>
            {formik.basic.dirty ? (
              <button
                type="submit"
                className={dummy}
                aria-disabled={!formik.basic.dirty || formik.basic.isSubmitting}
              >
                Save
              </button>
            ) : null}

            <button
              className={dummy}
              onClick={(e) => {
                e.preventDefault()
                formik.basic.resetForm()
                setEditing({ ...editing, basic: false })
              }}
            >
              Cancel Basic
            </button>
          </div>
        </form>
      </section>
      <section
        className={dummy}
        style={{ display: editing.basic ? 'none' : 'block' }}
      >
        <div className={dummy}>
          <pre>{JSON.stringify(formik.basic.values, false, 4)}</pre>
          <button
            onClick={(event) => {
              event.preventDefault()
              setEditing({ ...editing, basic: true })
            }}
          >
            Edit Basic
          </button>
        </div>
      </section>

      {petId !== 'add' ? (
        <>
          /***************** CARE ****************/
          <section
            className={dummy}
            style={{ display: editing.care ? 'none' : 'block' }}
          >
            <pre>{JSON.stringify(formik.care.values, false, 4)}</pre>
            <button
              onClick={() => {
                setEditing({ ...editing, care: true })
              }}
            >
              Edit Care
            </button>
          </section>
          <section
            className={dummy}
            style={{ display: editing.care ? 'block' : 'none' }}
          >
            <form id="pet-profile-care" onSubmit={formik.care.handleSubmit}>
              <fieldset className={dummy}>
                <div>
                  <label className={dummy} htmlFor="feeding">
                    feeding
                  </label>
                  <input
                    type="text"
                    id="feeding"
                    className={dummy}
                    name="feeding"
                    aria-required="true"
                    aria-invalid={
                      formik.care.errors.feeding && formik.care.touched.feeding
                    }
                    value={formik.care.values.feeding}
                    placeholder="feeding"
                    onChange={formik.care.handleChange}
                    onBlur={formik.care.handleBlur}
                  />
                  {formik.care.touched.feeding && formik.care.errors.feeding ? (
                    <div className={dummy}>{formik.care.errors.feeding}</div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="walking">
                    walking
                  </label>
                  <input
                    type="text"
                    id="walking"
                    className={dummy}
                    name="walking"
                    aria-required="true"
                    aria-invalid={
                      formik.care.errors.walking && formik.care.touched.walking
                    }
                    value={formik.care.values.walking}
                    placeholder="walking"
                    onChange={formik.care.handleChange}
                    onBlur={formik.care.handleBlur}
                  />
                  {formik.care.touched.walking && formik.care.errors.walking ? (
                    <div className={dummy}>{formik.care.errors.walking}</div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="sleeping">
                    sleeping
                  </label>
                  <input
                    type="text"
                    id="sleeping"
                    className={dummy}
                    name="sleeping"
                    aria-required="true"
                    aria-invalid={
                      formik.care.errors.sleeping &&
                      formik.care.touched.sleeping
                    }
                    value={formik.care.values.sleeping}
                    placeholder="sleeping"
                    onChange={formik.care.handleChange}
                    onBlur={formik.care.handleBlur}
                  />
                  {formik.care.touched.sleeping &&
                  formik.care.errors.sleeping ? (
                    <div className={dummy}>{formik.care.errors.sleeping}</div>
                  ) : null}
                </div>
              </fieldset>
              <div>
                {formik.care.dirty ? (
                  <button
                    type="submit"
                    className={dummy}
                    aria-disabled={
                      !formik.care.dirty || formik.care.isSubmitting
                    }
                  >
                    Save
                  </button>
                ) : null}

                <button
                  className={dummy}
                  onClick={(e) => {
                    e.preventDefault()
                    formik.care.resetForm()
                    setEditing({ ...editing, care: false })
                  }}
                >
                  Cancel Care
                </button>
              </div>
            </form>
          </section>
          /***************** HEALTH ****************/
          <section
            className={dummy}
            style={{ display: editing.health ? 'none' : 'block' }}
          >
            <pre>{JSON.stringify(formik.health.values, false, 4)}</pre>
            <button
              onClick={() => {
                setEditing({ ...editing, health: true })
              }}
            >
              Edit Health
            </button>
          </section>
          <section
            className={dummy}
            style={{ display: editing.health ? 'block' : 'none' }}
          >
            <form id="pet-profile-health" onSubmit={formik.health.handleSubmit}>
              <fieldset className={dummy}>
                <div>
                  <label className={dummy} htmlFor="allergies">
                    allergies
                  </label>
                  <input
                    type="text"
                    id="allergies"
                    className={dummy}
                    name="allergies"
                    aria-required="true"
                    aria-invalid={
                      formik.health.errors.allergies &&
                      formik.health.touched.allergies
                    }
                    value={formik.health.values.allergies}
                    placeholder="allergies"
                    onChange={formik.health.handleChange}
                    onBlur={formik.health.handleBlur}
                  />
                  {formik.health.touched.allergies &&
                  formik.health.errors.allergies ? (
                    <div className={dummy}>
                      {formik.health.errors.allergies}
                    </div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="medications">
                    medications
                  </label>
                  <input
                    type="text"
                    id="medications"
                    className={dummy}
                    name="medications"
                    aria-required="true"
                    aria-invalid={
                      formik.health.errors.medications &&
                      formik.health.touched.medications
                    }
                    value={formik.health.values.medications}
                    placeholder="medications"
                    onChange={formik.health.handleChange}
                    onBlur={formik.health.handleBlur}
                  />
                  {formik.health.touched.medications &&
                  formik.health.errors.medications ? (
                    <div className={dummy}>
                      {formik.health.errors.medications}
                    </div>
                  ) : null}
                </div>
              </fieldset>
              <div>
                {formik.health.dirty ? (
                  <button
                    type="submit"
                    className={dummy}
                    aria-disabled={
                      !formik.health.dirty || formik.health.isSubmitting
                    }
                  >
                    Save
                  </button>
                ) : null}

                <button
                  className={dummy}
                  onClick={(e) => {
                    e.preventDefault()
                    formik.health.resetForm()
                    setEditing({ ...editing, health: false })
                  }}
                >
                  Cancel Health
                </button>
              </div>
            </form>
          </section>
          /***************** VET ****************/
          <section
            className={dummy}
            style={{ display: editing.vet ? 'none' : 'block' }}
          >
            <pre>{JSON.stringify(formik.vet.values, false, 4)}</pre>
            <button
              onClick={() => {
                setEditing({ ...editing, vet: true })
              }}
            >
              Edit Vet
            </button>
          </section>
          <section
            className={dummy}
            style={{ display: editing.vet ? 'block' : 'none' }}
          >
            <form id="pet-profile-vet" onSubmit={formik.vet.handleSubmit}>
              <fieldset>
                <div>
                  <label className={dummy} htmlFor="name">
                    name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className={dummy}
                    name="name"
                    aria-required="true"
                    aria-invalid={
                      formik.vet.errors.name && formik.vet.touched.name
                    }
                    value={formik.vet.values.name}
                    placeholder="name"
                    onChange={formik.vet.handleChange}
                    onBlur={formik.vet.handleBlur}
                  />
                  {formik.vet.touched.name && formik.vet.errors.name ? (
                    <div className={dummy}>{formik.vet.errors.name}</div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="address">
                    address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className={dummy}
                    name="address"
                    aria-required="true"
                    aria-invalid={
                      formik.vet.errors.address && formik.vet.touched.address
                    }
                    value={formik.vet.values.address}
                    placeholder="address"
                    onChange={formik.vet.handleChange}
                    onBlur={formik.vet.handleBlur}
                  />
                  {formik.vet.touched.address && formik.vet.errors.address ? (
                    <div className={dummy}>{formik.vet.errors.address}</div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="email">
                    email
                  </label>
                  <input
                    type="text"
                    id="email"
                    className={dummy}
                    name="email"
                    aria-required="true"
                    aria-invalid={
                      formik.vet.errors.email && formik.vet.touched.email
                    }
                    value={formik.vet.values.email}
                    placeholder="email"
                    onChange={formik.vet.handleChange}
                    onBlur={formik.vet.handleBlur}
                  />
                  {formik.vet.touched.email && formik.vet.errors.email ? (
                    <div className={dummy}>{formik.vet.errors.email}</div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="telephone">
                    telephone
                  </label>
                  <input
                    type="text"
                    id="telephone"
                    className={dummy}
                    name="telephone"
                    aria-required="true"
                    aria-invalid={
                      formik.vet.errors.telephone &&
                      formik.vet.touched.telephone
                    }
                    value={formik.vet.values.telephone}
                    placeholder="telephone"
                    onChange={formik.vet.handleChange}
                    onBlur={formik.vet.handleBlur}
                  />
                  {formik.vet.touched.telephone &&
                  formik.vet.errors.telephone ? (
                    <div className={dummy}>{formik.vet.errors.telephone}</div>
                  ) : null}
                </div>
              </fieldset>
              <div>
                {formik.vet.dirty ? (
                  <button
                    type="submit"
                    className={dummy}
                    aria-disabled={!formik.vet.dirty || formik.vet.isSubmitting}
                  >
                    Save
                  </button>
                ) : null}

                <button
                  className={dummy}
                  onClick={(e) => {
                    e.preventDefault()
                    formik.vet.resetForm()
                    setEditing({ ...editing, vet: false })
                  }}
                >
                  Cancel Vet
                </button>
              </div>
            </form>
          </section>
          /***************** EMERGENCY ****************/
          <section
            className={dummy}
            style={{ display: editing.emergencyContact ? 'none' : 'block' }}
          >
            <pre>
              {JSON.stringify(formik.emergencyContact.values, false, 4)}
            </pre>
            <button
              onClick={() => {
                setEditing({ ...editing, emergencyContact: true })
              }}
            >
              Edit Emergency Contact
            </button>
          </section>
          <section
            className={dummy}
            style={{ display: editing.emergencyContact ? 'block' : 'none' }}
          >
            <form
              id="pet-profile-emergencyContact"
              onSubmit={formik.emergencyContact.handleSubmit}
            >
              <fieldset>
                <div>
                  <label className={dummy} htmlFor="name">
                    name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className={dummy}
                    name="name"
                    aria-required="true"
                    aria-invalid={
                      formik.emergencyContact.errors.name &&
                      formik.emergencyContact.touched.name
                    }
                    value={formik.emergencyContact.values.name}
                    placeholder="name"
                    onChange={formik.emergencyContact.handleChange}
                    onBlur={formik.emergencyContact.handleBlur}
                  />
                  {formik.emergencyContact.touched.name &&
                  formik.emergencyContact.errors.name ? (
                    <div className={dummy}>
                      {formik.emergencyContact.errors.name}
                    </div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="email">
                    email
                  </label>
                  <input
                    type="text"
                    id="email"
                    className={dummy}
                    name="email"
                    aria-required="true"
                    aria-invalid={
                      formik.emergencyContact.errors.email &&
                      formik.emergencyContact.touched.email
                    }
                    value={formik.emergencyContact.values.email}
                    placeholder="email"
                    onChange={formik.emergencyContact.handleChange}
                    onBlur={formik.emergencyContact.handleBlur}
                  />
                  {formik.emergencyContact.touched.email &&
                  formik.emergencyContact.errors.email ? (
                    <div className={dummy}>
                      {formik.emergencyContact.errors.email}
                    </div>
                  ) : null}
                </div>
                <div>
                  <label className={dummy} htmlFor="telephone">
                    telephone
                  </label>
                  <input
                    type="text"
                    id="telephone"
                    className={dummy}
                    name="telephone"
                    aria-required="true"
                    aria-invalid={
                      formik.emergencyContact.errors.telephone &&
                      formik.emergencyContact.touched.telephone
                    }
                    value={formik.emergencyContact.values.telephone}
                    placeholder="telephone"
                    onChange={formik.emergencyContact.handleChange}
                    onBlur={formik.emergencyContact.handleBlur}
                  />
                  {formik.emergencyContact.touched.telephone &&
                  formik.emergencyContact.errors.telephone ? (
                    <div className={dummy}>
                      {formik.emergencyContact.errors.telephone}
                    </div>
                  ) : null}
                </div>
              </fieldset>
              <div>
                {formik.emergencyContact.dirty ? (
                  <button
                    type="submit"
                    className={dummy}
                    aria-disabled={
                      !formik.emergencyContact.dirty ||
                      formik.emergencyContact.isSubmitting
                    }
                  >
                    Save
                  </button>
                ) : null}

                <button
                  className={dummy}
                  onClick={(e) => {
                    e.preventDefault()
                    formik.emergencyContact.resetForm()
                    setEditing({ ...editing, emergencyContact: false })
                  }}
                >
                  Cancel Emergency Contact
                </button>
              </div>
            </form>
          </section>
        </>
      ) : null}

      <section className={dummy}>
        <div className={dummy}>
          <Link key="userProfile" to={`/app/user-profile`}>
            User Profile
          </Link>
          {allPets.map((pet) => (
            <Link key={pet.id} to={`/app/pet-profile/${pet.id}`}>
              {pet.name}
            </Link>
          ))}
        </div>
      </section>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertOpen}
        message={alertMessage}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export default PetProfile
