import React, { useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import DoneIcon from '@material-ui/icons/Done'

import './leftNavToDo.scss'

const leftNavToDo = (props) => {
  const pet = props.pet
  const currentTodoSlug = props.todoSlug
  const allTodos = props.allTodos

  const [expanded, setExpanded] = useState('unset')
  const [toggled, setToggled] = useState(false)

  const isCompleted = (item) =>
    pet &&
    pet.todos &&
    pet.todos[item.slug] &&
    pet.todos[item.slug].status === 'Completed'

  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleMenuToggle = () => {
    const body = document.querySelector('body')
    body.classList.toggle('modal-open')
    setToggled(body.classList.contains('modal-open'))
  }

  const toggleMobileIfSkipped = () => {
    setToggled(false)
    const body = document.querySelector('body')
    body.classList.remove('modal-open')
  }

  return (
    <nav className="todoSidebar">
      <div className="todoNavBar">
        <a
          className="skip-nav"
          href="#todo"
          tabIndex="0"
          onClick={toggleMobileIfSkipped}
        >
          Skip to list
        </a>
        <button
          className={classNames({ active: toggled, menu__toggler: true })}
          onClick={handleMenuToggle}
          tabIndex="0"
        >
          &lt; {toggled ? 'Close' : 'All tasks'}
        </button>
      </div>
      <div
        className={classNames({ active: toggled, todoSidebarWrapper: true })}
      >
        {allTodos.content.map((item, index) => {
          return (
            <Accordion
              expanded={
                (expanded === 'unset' &&
                  item.listItems.filter((item) => item.slug === currentTodoSlug)
                    .length > 0) ||
                expanded === `panel${index}`
              }
              onChange={handleChange(`panel${index}`)}
              key={item.id}
            >
              <AccordionSummary
                aria-controls={`panel-content-${index}`}
                id={`panel-header-${index}`}
              >
                <div className="todoListInfo">
                  <div className="todoListCategory">{item.category}</div>
                  <div className="todoListTitle">{item.externalTitle}</div>
                  <div className="todoListCount">
                    {item.listItems.length -
                      item.listItems.filter(isCompleted).length}{' '}
                    left
                  </div>
                </div>
                {item.image && (
                  <Img
                    fluid={item.image.fluid}
                    alt={item.image.description}
                    className="todoListIcon"
                  />
                )}
              </AccordionSummary>
              {item.listItems.map((item) => {
                return (
                  <AccordionDetails
                    key={item.id}
                    className={classNames({
                      listItem: true,
                      completed: isCompleted(item),
                    })}
                  >
                    <Link to={`/todo/${item.slug}`} activeClassName="active">
                      <label>{item.externalTitle}</label>

                      <span className="checkbox">
                        {isCompleted(item) && <DoneIcon />}
                      </span>
                    </Link>
                  </AccordionDetails>
                )
              })}
            </Accordion>
          )
        })}
      </div>
    </nav>
  )
}

export default leftNavToDo
