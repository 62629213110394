import React from 'react'
import styled from 'styled-components'

import ContentfulImage from '../image'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const WelcomeMessage = styled.div`
  background: #475ec4;
  color: #fff;
  display: none;
  padding: 1rem 0 0;

  p.petParentGreeting strong {
    display: block;
    font-family: 'GTAmerica-Medium', sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }

  p {
    font-family: 'Feijoa-Bold', serif;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`

const ImgContainer = styled.div`
  display: flex;
  margin: 0;
`

const Greeting = styled.div`
  margin-bottom: 37px;
`

export default ({ componentContent, pet = {}, user = {} }) => {
  const { name: petName = '' } = pet
  const { first_name: petParentName } = user
  const { image, text } = componentContent

  const options = {
    renderText: (text) =>
      text
        .replace(/\[PET_NAME\]+/gm, petName)
        .split('\n')
        .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  }

  image.fixed.height = 147
  image.fixed.width = 210

  return (
    <WelcomeMessage>
      <ImgContainer>
        <ContentfulImage
          image={image}
          imageProps={{
            imgStyle: {
              objectPosition: 'bottom',
            },
          }}
          isFixed
        />
      </ImgContainer>
      <Greeting>
        <p className="petParentGreeting">
          <strong>Hello {petParentName},</strong>
        </p>
        {documentToReactComponents(text.json, options)}
      </Greeting>
    </WelcomeMessage>
  )
}
