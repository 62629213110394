import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import './leftNavArticle.scss'

const leftNavArticle = (props) => {
  const [expanded, setExpanded] = React.useState('unset')
  const currentArticleSlug = props.articleSlug

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const data = useStaticQuery(graphql`
    {
      contentfulContentContainer(
        contentful_id: { eq: "7JRSGe25snfTyMy8CM9mW5" }
      ) {
        id
        contentful_id
        externalTitle
        content {
          ... on ContentfulContentContainer {
            id
            externalTitle
            content {
              ... on ContentfulArticle {
                id
                category
                externalTitle
                slug
              }
            }
          }
        }
      }
    }
  `)
  return (
    <nav className="articleSidebar">
      <a className="skip-nav" href="#articleBody" tabIndex="0">
        Skip to article
      </a>
      {data.contentfulContentContainer.content.map((item, index) => {
        return (
          <Accordion
            expanded={
              (expanded === 'unset' &&
                item.content.filter((item) => item.slug === currentArticleSlug)
                  .length > 0) ||
              expanded === `panel${index}`
            }
            onChange={handleChange(`panel${index}`)}
            key={item.id}
          >
            <AccordionSummary
              aria-controls={`panel-content-${index}`}
              id={`panel-header-${index}`}
            >
              {item.externalTitle}
              {(expanded === 'unset' &&
                item.content.filter((item) => item.slug === currentArticleSlug)
                  .length > 0) ||
              expanded === `panel${index}` ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </AccordionSummary>

            {item.content.map((item) => {
              const categorySlug = ('' + item.category)
                .toLowerCase()
                .trim()
                .replace(/[^a-z0-9]+/g, '-')
              const path = `/tips-and-guides/${categorySlug}/${item.slug}`
              return (
                <AccordionDetails key={item.id} className="listItem">
                  <Link to={path} activeClassName="active">
                    {item.externalTitle}
                  </Link>
                </AccordionDetails>
              )
            })}
          </Accordion>
        )
      })}
    </nav>
  )
}

export default leftNavArticle
