import React, { useContext, useState, useEffect } from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { AuthDispatchContext, LOG_OUT } from '../../context/AuthContextProvider'
import moment from 'moment'
import 'moment/min/locales'

import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import {
  background,
  content,
  title,
  columnContainer,
  column,
  fieldLabel,
  fieldValue,
  logout,
  settings,
  withLink,
  link,
} from './profile.module.scss'
import classNames from 'classnames'

import { getPetsWithBreedInfo, getUser } from '../../services/zootopia'

const EditableField = ({ label, value, noSeparator, editing }) => {
  return (
    <label className={fieldLabel}>
      {label}
      <br />
      {editing ? (
        <input type="text" className={fieldValue} />
      ) : (
        <>
          {/* TODO: Fix the `&nbsp;` issue */}
          {value ? value : `nbsp;`}
        </>
      )}
    </label>
  )
}

const UserProfile = () => {
  const [userInfo, setUserInfo] = useState({})
  const [editingUser, setEditingUser] = useState(false)
  const [petInfo, setPetInfo] = useState({})
  const [editingPet, setEditingPet] = useState(false)

  useEffect(() => {
    let didCancel = false

    const fn = async () => {
      const allPets = await getPetsWithBreedInfo()
      const user = await getUser()

      setUserInfo({
        firstName: user.first_name,
        lastName: user.last_name,
        zipCode: user.postal_zip,
        experience:
          user.multiple_dog_owner ||
          allPets.length > 1 ||
          (allPets.length && !allPets[0].is_first_pet)
            ? 'Experienced pet parent'
            : 'First time pet parent',
        email: user.email,
      })

      if (navigator && navigator.languages && navigator.languages.length) {
        moment.locale(navigator.languages[0])
      }

      allPets.length &&
        setPetInfo({
          name: allPets[0].name,
          gender: allPets[0].gender === 'F' ? 'Female' : 'Male',
          breed: allPets[0].breed,
          birthday: moment.utc(allPets[0].birth_date).format('L'),
          gotchaversary: moment.utc(allPets[0].acquisition_date).format('L'),
          size: allPets[0].size,
        })
    }

    fn()

    return () => (didCancel = true)
  }, [])

  useEffect(() => {
    if (editingUser) {
      // TODO: Save the user
    }
  }, [editingUser])
  useEffect(() => {
    // TODO: Save the pet
  }, [editingPet])

  const handleEditUser = () => {
    // TODO
    console.log('- handleEditUser')
  }
  const handleEditPet = () => {
    // TODO
    console.log('- handleEditPet')
  }

  const dispatch = useContext(AuthDispatchContext)

  return (
    <Layout>
      <SEO
        bodyAttributes={{ class: 'account-page' }}
        title="Account"
        description="Your Floof user account settings."
      />

      <section className={background}>
        <div className={content}>
          <div className={classNames(title, withLink)}>
            <span>Account Details</span>
            {/*
            <a className={link} onClick={handleEditUser}>
              Edit
            </a>
           */}
          </div>
          <div className={columnContainer}>
            <div className={column}>
              <div>
                <EditableField label="First Name" value={userInfo.firstName} />
              </div>
              <div>
                <EditableField label="Last Name" value={userInfo.lastName} />
              </div>
              <div>
                <EditableField
                  label="Zip Code"
                  value={userInfo.zipCode}
                  noSeparator
                />
              </div>
            </div>
            <div className={column}>
              <div>
                <EditableField label="Experience" value={userInfo.experience} />
              </div>
              <div>
                <EditableField label="Email" value={userInfo.email} />
              </div>
              <div className={withLink}>
                <EditableField
                  label="Password"
                  value="••••••••••"
                  noSeparator
                />
                <Link to="/auth/forgot-password" className={link} tabIndex="0">
                  Reset
                </Link>
              </div>
            </div>
          </div>
          <div className={title}>
            <span>Pet Information</span>
            {/*
            <a className={link} onClick={handleEditPet}>
              Edit
            </a> 
            */}
          </div>
          <div className={columnContainer}>
            <div className={column}>
              <div>
                <EditableField label="Name" value={petInfo.name} />
              </div>
              <div>
                <EditableField label="Gender" value={petInfo.gender} />
              </div>
              <div>
                <EditableField
                  label="Breed"
                  value={petInfo.breed}
                  noSeparator
                />
              </div>
            </div>
            <div className={column}>
              <div>
                <EditableField label="Birthday" value={petInfo.birthday} />
              </div>
              <div>
                <EditableField
                  label="Gotchaday"
                  value={petInfo.gotchaversary}
                />
              </div>
              <div>
                {/* TODO: Fix the `&nbsp;` */}
                <EditableField label="Size" value={petInfo.size} noSeparator />
              </div>
            </div>
          </div>
          <div className={classNames(title, settings)}>
            <span>Settings</span>
          </div>
          <div className={columnContainer}>
            <div className={logout}>
              <a href="/" onClick={() => dispatch({ type: LOG_OUT })}>
                <i>
                  <ExitToAppIcon />
                </i>{' '}
                Log Out
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default UserProfile
