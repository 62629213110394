import React from 'react'

import ContentfulCardLink from '../components/cardWithLink'
import ContentfulRichText from '../components/richText'
import Grid from '../components/grid'

import { replacePetName } from '../utils/pet'

import { article, articleImageContainer, faqHeader } from './faq.module.scss'

export default ({ componentContent, pet = {} }) => {
  const { content, description, text, externalTitle } = componentContent
  const { name: petName } = pet

  const descriptionText = description || text

  const contentArticles = content.filter(
    ({ __typename }) =>
      __typename.toLowerCase().includes('article') ||
      __typename.toLowerCase().includes('imagewithtext')
  )

  const FaqArticles = contentArticles.map((content) => {
    if (content.__typename.toLowerCase().includes('article') && !content.url.includes('tips-and-guides')) {
      const categorySlug = ('' + content.category)
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9]+/g, '-')
      const path = `/tips-and-guides/${categorySlug}/${content.url}`
      content.url = path
    }
    return (
      <ContentfulCardLink
        content={content}
        key={content.id + Math.random()}
        imageProps={{ className: articleImageContainer }}
        cardProps={{ className: article }}
      />
    )
  })

  return (
    <section className={faqHeader}>
      <h2>{replacePetName(externalTitle, petName)}</h2>
      <ContentfulRichText content={descriptionText} />

      {contentArticles.length ? <Grid>{FaqArticles}</Grid> : null}
    </section>
  )
}
