import { useEffect, useState } from 'react'
import { getPetsWithBreedInfo } from '../services/zootopia'
/**
 * Replace pet name in a string
 * @param {string} text
 * @param {string} [petName]
 */
export const replacePetName = (text, petName) => {
  // returns ellipse block while petName is loading (prevents [PET_NAME] from flashing)
  if (! text.includes('[PET_NAME]')) return text
  if (!petName) return '...'

  return text.replace(/\[PET_NAME\]+/gm, petName)
}
/**
 * Convert weight in kilograms to pounds
 * @param {number} weightInKg
 */
export const convertKgToPounds = (weightInKg) =>
  Math.round(Math.round((weightInKg * 2.2 + Number.EPSILON) * 10) / 10)
/**
 * Convert height in centimeter to inches
 * @param {number} heightInCm
 */
export const convertCmToInches = (heightInCm) =>
  Math.round(Math.round((heightInCm * 0.3937 + Number.EPSILON) * 10) / 10)
/**
 * Effect that retrieves pet details for components
 */
export const usePetsApi = () => {
  const [pets, setPets] = useState({})
  const [petName, setPetName] = useState('')
  const [primaryBreedUrl, setPrimaryBreedUrl] = useState('')
  const [hasNoBreedInfo, setHasNoBreedInfo] = useState(false)
  const [breedInfo, setBreedInfo] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      const allPets = await getPetsWithBreedInfo()
      if (!didCancel && allPets.length) {
        // MVP, TODO: enable multi pet
        setPets(allPets[0])
        setPetName(allPets[0].name)
        setHasNoBreedInfo(!allPets[0].breed_info.length)

        if (allPets[0].breed_info.length) {
          setBreedInfo(allPets[0].breed_info)
          setPrimaryBreedUrl(`/app/breed/${allPets[0].breed_info[0].slug}`)
        }
      }
    }

    fetchData()
    return () => (didCancel = true)
  }, [])

  return { breedInfo, hasNoBreedInfo, pets, petName, primaryBreedUrl }
}
/**
 * Generate Floof Tagline
 * @param {Array} breedInfo
 */
export const floofTagline = (breedInfo) => {
  const defaultTagline = 'A super supermix'
  return breedInfo && breedInfo.length === 1
    ? breedInfo[0].floofTagline
    : defaultTagline
}
