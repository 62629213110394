import React, { useContext } from 'react'
import styled from 'styled-components'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Wrapper = styled.div`
  margin: 57px auto;
  max-width: 464px;
  padding: 48px;
  text-align: center;
`
const Credit = styled.div`
  color: #999;
  font-size: 0.75rem;
  font-style: italic;
`
const Graphic = styled.div`
  margin: 0 auto 1rem;
  max-width: 100%;
`

const EmbeddedEntry = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulImage {
        id
        contentful_id
        internalTitle
        credit
        image {
          description
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Graphic>
        <Img
          fluid={data.contentfulImage.image.fluid}
          alt={data.contentfulImage.image.description}
        />
      </Graphic>
      <Credit>Credit: {data.contentfulImage.credit}</Credit>
    </Wrapper>
  )
}

export default EmbeddedEntry
