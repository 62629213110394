import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  background: #fff;

  h2 {
    font-family: 'Feijoa-Bold', serif;
    font-size: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-family: 'GTAmerica-Medium', sans-serif;
    text-align: center;
  }

  [data-richtext] {
    font-size: 1rem;
    text-align: left;

    p {
      font-family: 'GTAmerica', sans-serif;
    }
  }

  a.view-all {
    color: #4358d2;
    display: block;
    font-family: 'GTAmerica-Bold', sans-serif;
    line-height: 1.5rem;
    margin-bottom: 1rem;

    i {
      vertical-align: middle;

      svg {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 768px) {
    display: block;
    grid-column: 4 / last-col;
    grid-row: 1;

    [data-richtext] p {
      font-size: 1.125rem !important;
    }

    a.view-all {
      font-size: 1.125rem;
    }
  }
`

export default ({ children }) => <Container>{children}</Container>
