import React, { useContext } from 'react'
import styled from 'styled-components'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { AuthStateContext } from '../context/AuthContextProvider'
import { getVetInsightToken } from '../services/zootopia'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Wrapper = styled.div`
  border: 2px solid #4b8763;
  border-width: 2px 0;
  margin: 57px auto;
  max-width: 464px;
  padding: 48px;
  text-align: center;
`

const Graphic = styled.div`
  margin: 0 auto 1rem;
  max-width: 64px;
`

const Button = styled.a`
  background-color: #4b8763;
  color: white;
  display: block;
  font-size: 1rem;
  padding: 18px;
`

const initVetChat = async () => {
  const bearerToken = await getVetInsightToken()

  vetChatWidget.send('init', {
    client_id: 'floof',
    theme: 'floof',
    auth_token: bearerToken,
  })
}

const VetInsights = () => {
  const startVetChat = (event) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'chat', chatClick: 'yes' })

    event.preventDefault()
    event.stopPropagation()
    vetChatWidget.send('open')
  }

  const { loggedIn } = useContext(AuthStateContext) || { loggedIn: false }

  if (loggedIn) {
    initVetChat()
  }

  const data = useStaticQuery(graphql`
    {
      contentfulImageWithText(contentful_id: { eq: "3q37HQZ58cvZLPJDNzpNNN" }) {
        id
        contentful_id
        internalTitle
        text {
          json
        }
        image {
          description
          fluid(maxWidth: 80, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Graphic>
        <Img
          fluid={data.contentfulImageWithText.image.fluid}
          alt={data.contentfulImageWithText.image.description}
        />
      </Graphic>
      {documentToReactComponents(data.contentfulImageWithText.text.json)}
      <Button className="btnLabel" href="#" onClick={startVetChat}>
        Ask us a question
      </Button>
    </Wrapper>
  )
}

export default VetInsights
