import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../layout'

import PetProfileCard from '../pet/profileCard'
import LeftNav from '../leftNav'

import HomePageContent from '../pageContent'
import WelcomeMessage from '../home/welcome'
import PlanningPetParent from '../gridContainer'

import ContentfulFaqLandingPageContent from '../../templates/faqLandingPageContent'

import { getPetsWithBreedInfo, getUser } from '../../services/zootopia'

import Loadable from '@loadable/component'
const PetBreedDetails = Loadable(() => import('../pet/breedDetails'))
const CuratedPetProducts = Loadable(() => import('../pet/curatedList'))
const ContentfulFaq = Loadable(() => import('../../templates/faq'))

const StyledLayout = styled(Layout)`
  .main {
    animation: fade-in 0.5s ease-out;
    background: #f5f5f5;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
    }
  }
`
const StyledLeftNav = styled(LeftNav)`
  border: 1px solid #ebecef;
`
const PageContent = styled.div`
  padding: 1.5rem 0;
  margin: 0 1rem;

  @media (max-width: 768px) {
    &:first-of-type {
      padding: 0;
    }
  }

  @media (min-width: 768px) {
    padding: 2.5rem 2rem;
    margin: 0 2rem;

    [class^='faq-module--ContentfulFaq'] {
      padding: 0;

      [data-richtext] {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
      border-top: none;

      &:not(:nth-of-type(2)) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
`

export default () => {
  const [pets, setPets] = useState({})
  const [petName, setPetName] = useState('')
  const [user, setUser] = useState({})

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let didCancel = false

    const fetchPets = async () => {
      const allPets = await getPetsWithBreedInfo()
      if (!didCancel && allPets.length) {
        setPets(allPets)
        setPetName(allPets[0].name)
      }
    }

    const fetchUser = async () => {
      const user = await getUser()
      if (!didCancel) setUser(user)
    }

    const fetchAll = async () => {
      await Promise.all([fetchUser(), fetchPets()])
    }
    fetchAll()

    setLoading(false)

    return () => (didCancel = true)
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query FloofHomepage {
          allContentfulHomepage {
            nodes {
              componentContent: content {
                ... on ContentfulContentContainer {
                  id
                  externalTitle
                  content {
                    ... on ContentfulContentContainer {
                      id
                      description {
                        json
                      }
                      externalTitle
                      content {
                        ... on ContentfulImageWithText {
                          externalTitle
                          id
                          image {
                            file {
                              contentType
                            }
                            fixed {
                              ...GatsbyContentfulFixed_withWebp
                            }
                            svg {
                              content
                              dataURI
                            }
                            title
                          }
                          description: text {
                            json
                          }
                          url
                        }
                        ... on ContentfulLink {
                          id
                          externalTitle
                          url
                          titleAttribute
                        }
                      }
                    }
                    ... on ContentfulArticle {
                      category
                      id
                      image: mainImage {
                        fixed {
                          ...GatsbyContentfulFixed_withWebp
                        }
                      }
                      url: slug
                      externalTitle
                    }
                    ... on ContentfulToDoList {
                      id
                      externalTitle
                      url: slug
                      image {
                        fixed {
                          ...GatsbyContentfulFixed_withWebp
                        }
                      }
                    }
                    ... on ContentfulImageWithText {
                      id
                      externalTitle
                      image {
                        file {
                          contentType
                        }
                        fixed {
                          ...GatsbyContentfulFixed_withWebp
                        }
                        svg {
                          content
                          dataURI
                        }
                        title
                      }
                      url
                    }
                    ... on ContentfulLink {
                      id
                      url
                    }
                  }
                  text: description {
                    json
                  }
                  image {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
                ... on ContentfulImageWithText {
                  id
                  externalTitle
                  text {
                    json
                  }
                  image {
                    file {
                      contentType
                    }
                    fixed {
                      ...GatsbyContentfulFixed_withWebp
                    }
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                    svg {
                      content
                      dataURI
                    }
                    title
                  }
                }
                ... on ContentfulCarousel {
                  id
                  carouselItems {
                    externalTitle
                    description {
                      json
                    }
                    images: media {
                      id
                      fixed(height: 200, width: 200, quality: 100) {
                        ...GatsbyContentfulFixed_withWebp
                      }
                    }
                    id
                  }
                  carouselSubtitle
                  externalTitle
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          0: welcomeContent,
          1: planningPetParentContent,
          2: defaultBreedDetailsContent,
          3: curatedListContent,
          4: needHelpContent,
          5: faqContent,
        } = data.allContentfulHomepage.nodes[0].componentContent

        return (
          <StyledLayout as={Layout} layoutProps={{ className: 'homepage' }}>
            {!loading ? (
              <div>
                <StyledLeftNav>
                  <PetProfileCard pets={pets} user={user} />
                </StyledLeftNav>
                <HomePageContent>
                  <WelcomeMessage
                    componentContent={welcomeContent}
                    pet={pets[0]}
                    user={user}
                  />

                  <PageContent key={planningPetParentContent.id}>
                    <PlanningPetParent
                      componentContent={{
                        petName,
                        ...planningPetParentContent,
                      }}
                    />
                  </PageContent>

                  <PageContent key="pet-breed-details">
                    <PetBreedDetails
                      componentContent={defaultBreedDetailsContent}
                      pet={pets[0]}
                    />
                  </PageContent>

                  <PageContent key={curatedListContent.id}>
                    <CuratedPetProducts
                      componentContent={curatedListContent}
                      pet={pets[0]}
                    />
                  </PageContent>

                  <PageContent>
                    <ContentfulFaq
                      componentContent={faqContent}
                      chatItems={faqContent.content[0].content}
                      commonlyAskedItems={{
                        content: faqContent.content[1].content,
                        externalTitle: faqContent.content[1].externalTitle,
                      }}
                    >
                      <ContentfulFaqLandingPageContent
                        componentContent={needHelpContent}
                        pet={pets[0]}
                      />
                    </ContentfulFaq>
                  </PageContent>
                </HomePageContent>
              </div>
            ) : null}
          </StyledLayout>
        )
      }}
    />
  )
}
