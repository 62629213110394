import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { ContentfulRichText } from './richText.module.scss'

export default ({ content }) => {
  return (
    <>
      {content && (
        <div className={ContentfulRichText} data-richtext>
          {documentToReactComponents(content.json)}
        </div>
      )}
    </>
  )
}
