import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { floofTagline } from '../../utils/pet'

const ProfileContainer = styled.div`
  font-family: 'GTAmerica', sans-serif;

  h2 {
    font-family: 'Feijoa-Bold', serif;
    font-size: 1.375rem;
    text-align: left;
  }

  h3 {
    font-family: 'GTAmerica-Bold', sans-serif;
    font-size: 0.875rem;
  }

  p {
    font-size: 0.875rem;
  }

  header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;

    @media (min-width: 1096px) {
      padding: 0 1rem 1.25rem;

      p {
        margin-bottom: 0;
      }
    }
  }

  header + div {
    @media (min-width: 1096px) {
      padding: 0 1rem 1.25rem;
    }
  }
`
const PetParentDetails = styled.div`
  p span {
    text-transform: capitalize;
  }
`

const renderBirthDate = (birthDate) => moment(birthDate).fromNow(true)

export default ({ pets, user }) => {
  const petParentName = `${user.first_name} ${user.last_name}`

  return (
    <ProfileContainer>
      {pets.length &&
        pets.map(({ id, name, birth_date, breed_info, is_first_pet, size }) => (
          <div key={id}>
            <header>
              <h2>{name}</h2>
              <p>{floofTagline(breed_info)}</p>
            </header>
            <div>
              <div>
                <h3>Age</h3>
                <p>{renderBirthDate(birth_date)}</p>
              </div>
              <div>
                <h3>Breed{breed_info.length > 1 ? 's' : ''}</h3>
                <p>{breed_info.map(({ name }) => name).join(' / ')} </p>
              </div>
              <div>
                <h3>Size</h3>
                <p>{size}</p>
              </div>
              <PetParentDetails>
                <h3>Parent</h3>
                <p>
                  <span>{petParentName}</span>
                  {is_first_pet && ' (first time)'}
                </p>
              </PetParentDetails>
            </div>
          </div>
        ))}
    </ProfileContainer>
  )
}
