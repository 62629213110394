import React from 'react'

import ContentfulImage from './image'
import ContentfulLink from './link'
import ContentfulRichText from './richText'

import { replacePetName } from '../utils/pet'

export default ({ cardProps, content, children, imageProps }) => {
  const { description, externalTitle, image, onClick, petName, url } = content

  return (
    <div {...cardProps}>
      <ContentfulLink
        link={{ linkText: externalTitle, linkUrl: url, onClick: onClick }}
      >
        <ContentfulImage image={image} imageProps={imageProps} isFixed />
        <section>
          {externalTitle ? (
            <h3>{replacePetName(externalTitle, petName)}</h3>
          ) : null}
          {description ? <ContentfulRichText content={description} /> : null}
          {children ? children : null}
        </section>
      </ContentfulLink>
    </div>
  )
}
