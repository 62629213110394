import React from 'react'

import styled from 'styled-components'
import classNames from 'classnames'

const Container = styled.div`
  display: none;

  div.content {
    background: #fff;
    padding: 1.6875rem 1rem;
  }

  @media (min-width: 768px) {
    display: block;
    grid-column: 1 / span 3;
    grid-row: 1;
  }
`

export default ({ className, children }) => (
  <Container>
    <div className={classNames('content', className)}>{children}</div>
  </Container>
)
