import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SEO from '../components/seo'

import Layout from '../components/layout'
import LeftnavToDo from '../components/leftNavToDo'

import './ContentfulToDo.scss'

import {
  getPets,
  appendPetsWithTodos,
  putFloofTodo,
} from '../services/zootopia'

const isBrowser = typeof window !== 'undefined'

const TodoTemplate = ({ data: { todo, allTodos } }) => {
  const [pets, setPets] = useState([{ todos: {} }])
  const [petIdx, setPetIdx] = useState(0)
  const [todoStatus, setTodoStatus] = useState(false)

  if (!todo || !todo.slug) {
    if (isBrowser) {
      window.location.href = `/todo/${allTodos.content[0].listItems[0].slug}`
    }
    return <></>
  }

  useEffect(() => {
    const fn = async () => {
      const petsData = await getPets()
      const petsDataWithTodos = await appendPetsWithTodos(petsData)

      setPets(petsDataWithTodos)

      setTodoStatus(
        petsDataWithTodos &&
          petsDataWithTodos[petIdx] &&
          petsDataWithTodos[petIdx].todos &&
          petsDataWithTodos[petIdx].todos[todo.slug] &&
          petsDataWithTodos[petIdx].todos[todo.slug].status === 'Completed'
      )
    }
    fn()
  }, [petIdx])

  const handleOnClick = async (checked) => {
    setTodoStatus(checked)

    const response = await putFloofTodo(
      pets[petIdx].id,
      todo.slug,
      {},
      checked ? 'Completed' : 'Started'
    )

    // reload pets for left nav
    const petsData = await getPets()
    const petsDataWithTodos = await appendPetsWithTodos(petsData)

    setPets(petsDataWithTodos)

    // console.log(response)
  }

  // handles navigation math
  let todoGroup = []
  let todoGroupNextIdx = null
  let todoGroupPrevIdx = null
  allTodos.content.forEach((group) => {
    group.listItems.forEach((item, idx) => {
      if (item.slug === todo.slug) {
        todoGroup = group.listItems
        todoGroupPrevIdx = idx === 0 ? null : idx - 1
        todoGroupNextIdx = idx === group.listItems.length - 1 ? null : idx + 1
      }
    })
  })

  return (
    <Layout>
      <SEO bodyAttributes={{ class: 'todo' }} />

      <div>
        <LeftnavToDo
          pet={pets[petIdx]}
          todoSlug={todo.slug}
          allTodos={allTodos}
        />

        <div className="todoMainContent" id="todo">
          <div className="todoCardWrapper">
            <div className="todoCard">
              <div className="todoCard-main">
                <h1>{todo.externalTitle}</h1>

                <div className="todoDescription">
                  {documentToReactComponents(todo.description.json)}
                </div>

                <div className="todoCta">
                  {!todoStatus ? (
                    <button onClick={() => handleOnClick(true)}>
                      Mark as complete
                      <span className="checkbox">&nbsp;</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => handleOnClick(false)}
                      className="complete"
                    >
                      <i className="confetti" /><span>Item complete</span><i className="confetti" />
                    </button>
                  )}
                </div>
              </div>

              <div className="todoCard-side">
                <h3>Why it's important</h3>
                {documentToReactComponents(todo.whyImportant.json)}
                {todo.helpfulContent && (
                  <>
                    <h3>Helpful content</h3>
                    <ul>
                      {todo.helpfulContent.map((item) => {
                        const categorySlug = ('' + item.category)
                          .toLowerCase()
                          .trim()
                          .replace(/[^a-z0-9]+/g, '-')
                        const path = `/tips-and-guides/${categorySlug}/${item.slug}`
                        return (
                          <li key={item.id}>
                            <Link to={path}>{item.externalTitle}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>

            <div className="todoNavigation">
              {todoGroupPrevIdx !== null && (
                <Link
                  to={`/todo/${todoGroup[todoGroupPrevIdx].slug}`}
                  role="button"
                  className="btn btnTodo"
                >
                  Previous
                </Link>
              )}

              {todoGroupNextIdx !== null && (
                <Link
                  to={`/todo/${todoGroup[todoGroupNextIdx].slug}`}
                  role="button"
                  className="btn btnTodo"
                >
                  Next
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TodoTemplate
