import React from 'react'
import styled from 'styled-components'

import ContentfulCardLink from './cardWithLink'
import ContentfulRichText from './richText'

import { replacePetName } from '../utils/pet'

const ContentfulGridContainer = styled.div`
  [data-richtext] {
    margin-top: 0.25rem;

    @media (min-width: 768px) {
      margin-top: 0.75rem;
    }
  }
`
const ContentfulGrid = styled.section.attrs((props) => ({
  justifyContent: props.justifyContent || 'space-evenly',
}))`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1.5rem;

  h3 {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  @media (min-width: 768px) {
    min-width: 22rem;
    justify-content: ${(props) => props.justifyContent};
  }
`
const ContentfulGridItem = styled.div`
  margin-bottom: 1.0625rem;
  width: 10rem;

  @media (min-width: 768px) {
    margin-right: 1.0625rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

export default ({ componentContent }) => {
  const { content, externalTitle, petName = '', text } = componentContent

  const ContentfulGridItems = content.map((content) => {
    content.image.fixed.height = 160
    content.image.fixed.width = 160

    content.petName = petName

    return (
      <ContentfulGridItem key={content.id}>
        <ContentfulCardLink content={content} />
      </ContentfulGridItem>
    )
  })

  const justifyContent =
    ContentfulGridItems.length < 4 ? 'flex-start' : 'space-between'

  return (
    <ContentfulGridContainer>
      <h2>{replacePetName(externalTitle, petName)}</h2>
      <ContentfulRichText content={text} />

      {content.length ? (
        <ContentfulGrid justifyContent={justifyContent}>
          {ContentfulGridItems}
        </ContentfulGrid>
      ) : null}
    </ContentfulGridContainer>
  )
}
