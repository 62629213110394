import React from 'react'

import { navigate } from 'gatsby'

import { isLoggedIn, setRedirectPath } from '../../services/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn()) {
    setRedirectPath(location.pathname)

    typeof window !== 'undefined' && navigate('/auth/login')

    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
