import React, { useContext, useState, useEffect } from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { AuthDispatchContext, LOG_OUT } from '../../context/AuthContextProvider'
import moment from 'moment'
import 'moment/min/locales'

import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { useFormik } from 'formik'
import { object, string } from 'yup'
import { useAlert, Alert } from '../../hooks/alert'
import Snackbar from '@material-ui/core/Snackbar'

import { forgotPassword, confirmForgotPassword } from '../../services/auth'

import {
  dummy,
  background,
  content,
  title,
  columnContainer,
  column,
  fieldLabel,
  fieldValue,
  logout,
  settings,
  withLink,
  link,
} from './profile.module.scss'
import classNames from 'classnames'

import {
  getPetsWithBreedInfo,
  getUser,
  updateUser,
} from '../../services/zootopia'

const UserProfile = () => {
  const [userInfo, setUserInfo] = useState({})
  const [petInfo, setPetInfo] = useState([])
  const [editing, setEditing] = useState({
    basic: false,
    password: false,
    password_entry: false,
    email: false,
  })

  const loadData = async () => {
    const allPets = await getPetsWithBreedInfo()
    const user = await getUser()

    setUserInfo({
      ...user,
      experience:
        user.multiple_dog_owner ||
        allPets.length > 1 ||
        (allPets.length && !allPets[0].is_first_pet)
          ? 'Experienced pet parent'
          : 'First time pet parent',
    })

    setPetInfo(allPets)
  }

  useEffect(() => {
    let didCancel = false
    loadData()
    return () => (didCancel = true)
  }, [])

  const dispatch = useContext(AuthDispatchContext)

  const [alertOpen, alertMessage, openAlert, closeAlert] = useAlert()
  const handleAlertClose = (event, reason) => closeAlert(reason)

  const formik = {
    basic: useFormik({
      initialValues: {
        firstName: userInfo.first_name,
        lastName: userInfo.last_name,
        email: userInfo.email,
        zipCode: userInfo.postal_zip,
        homePhone: userInfo.home_phone,
        multipleDogOwner: userInfo.multiple_dog_owner,
      },
      enableReinitialize: true,
      validationSchema: object({
        firstName: string().required('First name is required'),
        lastName: string().required('Last name is required'),
        email: string().email().required('Email is required'),
        zipCode: string()
          .required('Your ZIP Code is required')
          .matches(
            /^[0-9]{5}(?:-[0-9]{4})?$/,
            'Your ZIP Code must be 5 or 9 digits long'
          ),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          await updateUser({
            first_name: values.firstName,
            last_name: values.lastName,
            postal_zip: values.zipCode || '',
            home_phone: values.homePhone || '',
            multiple_dog_owner: '' + values.multipleDogOwner,
          })
          await loadData()
          resetForm({})
          setEditing({ ...editing, password_entry: false })
        } catch (err) {
          openAlert(err.message)
        }
      },
    }),
    password: useFormik({
      initialValues: {
        email: userInfo.email,
        verificationCode: '',
        password: '',
      },
      enableReinitialize: true,
      validationSchema: object({
        email: string().required('Email is required'),
        verificationCode: string().required(
          'A valid verification code is required'
        ),
        password: string()
          .required('Password is required')
          .min(8, 'Password must be at least 8 characters long')
          .matches(
            /[a-z]/,
            'Password must contain at least one lowercase letter'
          )
          .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase letter'
          )
          .matches(/[0-9]/, 'Password must contain at least one number')
          .matches(
            /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/,
            'Password must contain at least one special character'
          ),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          await confirmForgotPassword(values)
        } catch (err) {
          openAlert(err.message)
        }

        await loadData()
        resetForm({})
        setEditing({ ...editing, password: false, password_entry:false })
      },
    }),
    email: useFormik({
      initialValues: {
        email: userInfo.email
      },
      enableReinitialize: true,
      validationSchema: object({
        email: string().email().required('Email is required'),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          await updateUser({
            email: values.email,
          })
          await loadData()
          resetForm({})
          setEditing({ ...editing, email: false })
        } catch (err) {
          openAlert(err.message)
        }
      },
    }),
  }

  return (
    <Layout>
      <SEO
        bodyAttributes={{ class: 'account-page' }}
        title="Account"
        description="Your Floof user account settings."
      />
      <b>{editing.basic}</b>
      /********************** BAsic ***************/
      <section
        className={background}
        style={{ display: editing.basic ? 'block' : 'none' }}
      >
        <div className={content}>
          <form id="signup-form" onSubmit={formik.basic.handleSubmit}>
            <span>Account Details</span>

            <fieldset className={dummy}>
              <div>
                <label className={dummy} htmlFor="firstName">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className={dummy}
                  name="firstName"
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.firstName &&
                    formik.basic.touched.firstName
                  }
                  value={formik.basic.values.firstName}
                  placeholder="First Name"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.firstName &&
                formik.basic.errors.firstName ? (
                  <div className={dummy}>{formik.basic.errors.firstName}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="lastName">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className={dummy}
                  name="lastName"
                  value={formik.basic.values.lastName}
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.lastName &&
                    formik.basic.touched.lastName
                  }
                  placeholder="Last Name"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.lastName &&
                formik.basic.errors.lastName ? (
                  <div className={dummy}>{formik.basic.errors.lastName}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="zipCode">
                  ZIP Code
                </label>
                <input
                  type="text"
                  id="zipCode"
                  className={dummy}
                  name="zipCode"
                  value={formik.basic.values.zipCode}
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.zipCode && formik.basic.touched.zipCode
                  }
                  placeholder="ZIP Code"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.zipCode && formik.basic.errors.zipCode ? (
                  <div className={dummy}>{formik.basic.errors.zipCode}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="homePhone">
                  Phone
                </label>
                <input
                  type="text"
                  id="homePhone"
                  className={dummy}
                  name="homePhone"
                  value={formik.basic.values.homePhone}
                  aria-required="true"
                  aria-invalid={
                    formik.basic.errors.homePhone &&
                    formik.basic.touched.homePhone
                  }
                  placeholder="Phone"
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                {formik.basic.touched.homePhone &&
                formik.basic.errors.homePhone ? (
                  <div className={dummy}>{formik.basic.errors.homePhone}</div>
                ) : null}
              </div>
              <div>
                <label className={dummy} htmlFor="multipleDogOwner">
                  Experience
                </label>
                <input
                  id="c1"
                  type="checkbox"
                  name="multipleDogOwner"
                  checked={formik.basic.values.multipleDogOwner}
                  onChange={formik.basic.handleChange}
                  onBlur={formik.basic.handleBlur}
                />
                <label htmlFor="c1" className={dummy}>
                  I have more than one dog
                </label>
                {formik.basic.touched.multipleDogOwner &&
                formik.basic.errors.multipleDogOwner ? (
                  <div className={dummy}>
                    {formik.basic.errors.multipleDogOwner}
                  </div>
                ) : null}
              </div>
              <div>
                {formik.basic.dirty ? (
                  <button
                    type="submit"
                    className={dummy}
                    aria-disabled={
                      !formik.basic.dirty || formik.basic.isSubmitting
                    }
                  >
                    Save
                  </button>
                ) : null}

                <button
                  className={dummy}
                  onClick={(e) => {
                    e.preventDefault()
                    formik.basic.resetForm()
                    setEditing({ ...editing, basic: false })
                  }}
                >
                  Cancel
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </section>
      <section
        className={background}
        style={{ display: editing.basic ? 'none' : 'block' }}
      >
        <div class={content}>
          <pre>{JSON.stringify(formik.basic.values, false, 4)}</pre>
          <button
            onClick={() => {
              setEditing({ ...editing, basic: true })
            }}
          >
            Edit
          </button>
        </div>
      </section>

      /***** PASSWORD ****/
      <section
        className={background}
        style={{ display: editing.password ? 'block' : 'none' }}
      >
        <div className={content}>
          <div
            className={dummy}
            style={{ display: editing.password_entry ? 'none' : 'block' }}
          >
            <button
              onClick={async (event) => {
                event.preventDefault()
                await forgotPassword({
                  email: userInfo.email,
                })
                setEditing({ ...editing, password_entry: true })
              }}
            >
              Request Verification Code
            </button>
            <button
              onClick={async (event) => {
                event.preventDefault()
                setEditing({ ...editing, password_entry: true })
              }}
            >
              I already have one
            </button>
          </div>

          <div style={{ display: editing.password_entry ? 'block' : 'none' }}>
            <form id="signup-form" onSubmit={formik.password.handleSubmit}>
              <fieldset className={dummy}>
                <div>
                  <label className={dummy} htmlFor="verificationCode">
                    Verification Code
                  </label>
                  <input
                    type="text"
                    id="verificationCode"
                    className={dummy}
                    name="verificationCode"
                    aria-required="true"
                    aria-invalid={
                      formik.password.errors.verificationCode &&
                      formik.password.touched.verificationCode
                    }
                    value={formik.password.values.verificationCode}
                    placeholder="Verfication Code"
                    onChange={formik.password.handleChange}
                    onBlur={formik.password.handleBlur}
                  />
                  {formik.password.touched.verificationCode &&
                  formik.password.errors.verificationCode ? (
                    <div className={dummy}>
                      {formik.password.errors.verificationCode}
                    </div>
                  ) : null}
                </div>

                <div>
                  <label className={dummy} htmlFor="password">
                    New Password
                  </label>
                  <input
                    type="text"
                    id="password"
                    className={dummy}
                    name="password"
                    value={formik.password.values.password}
                    aria-required="true"
                    aria-invalid={
                      formik.password.errors.password &&
                      formik.password.touched.password
                    }
                    placeholder="New Password"
                    onChange={formik.password.handleChange}
                    onBlur={formik.password.handleBlur}
                  />
                  {formik.password.touched.password &&
                  formik.password.errors.password ? (
                    <div className={dummy}>
                      {formik.password.errors.password}
                    </div>
                  ) : null}
                </div>
                <div>
                  {formik.password.dirty ? (
                    <button
                      type="submit"
                      className={dummy}
                      aria-disabled={
                        !formik.password.dirty || formik.password.isSubmitting
                      }
                    >
                      Save
                    </button>
                  ) : null}

                  <button
                    className={dummy}
                    onClick={(e) => {
                      e.preventDefault()
                      formik.password.resetForm()
                      setEditing({
                        ...editing,
                        password: false,
                        password_entry: false,
                      })
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section>
      <section
        className={background}
        style={{ display: editing.password ? 'none' : 'block' }}
      >
        <div class={content}>
          <button
            onClick={() => {
              setEditing({ ...editing, password: true })
            }}
          >
            Change Password
          </button>
        </div>
      </section>

      /***** EMAIL ****/
      <section
        className={background}
        style={{ display: editing.email ? 'block' : 'none' }}
      >
        <div className={content}>
          <div style={{ display: editing.email ? 'block' : 'none' }}>
            <form id="signup-form" onSubmit={formik.email.handleSubmit}>
              <fieldset className={dummy}>

                <div>
                  <label className={dummy} htmlFor="email">
                    New Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    className={dummy}
                    name="email"
                    value={formik.email.values.email}
                    aria-required="true"
                    aria-invalid={
                      formik.email.errors.email &&
                      formik.email.touched.email
                    }
                    placeholder="New Password"
                    onChange={formik.email.handleChange}
                    onBlur={formik.email.handleBlur}
                  />
                  {formik.email.touched.email &&
                  formik.email.errors.email ? (
                    <div className={dummy}>
                      {formik.email.errors.email}
                    </div>
                  ) : null}
                </div>
                <div>
                  {formik.email.dirty ? (
                    <button
                      type="submit"
                      className={dummy}
                      aria-disabled={
                        !formik.email.dirty || formik.email.isSubmitting
                      }
                    >
                      Save
                    </button>
                  ) : null}

                  <button
                    className={dummy}
                    onClick={(e) => {
                      e.preventDefault()
                      formik.email.resetForm()
                      setEditing({
                        ...editing,
                        email: false
                      })
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section>
      <section
        className={background}
        style={{ display: editing.email ? 'none' : 'block' }}
      >
        <div class={content}>
          <button
            onClick={() => {
              setEditing({ ...editing, email: true })
            }}
          >
            Change Email
          </button>
        </div>
      </section>
      <section className={background}>
        <div className={content}>
          <Link key="userProfile" to={`/app/user-profile`}>
            User Profile
          </Link>
          {petInfo.map((pet) => (
            <Link key={pet.id} to={`/app/pet-profile/${pet.id}`}>
              {pet.name}
            </Link>
          ))}
        </div>
      </section>
      <section className={background}>
        <div className={content}>
          <div className={logout}>
            <a href="/" onClick={() => dispatch({ type: LOG_OUT })}>
              <i>
                <ExitToAppIcon />
              </i>{' '}
              Log Out
            </a>
          </div>
        </div>
      </section>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertOpen}
        message={alertMessage}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export default UserProfile
